<template>
  <page v-bind="{ loading }" :icon="isPrimaryUser ? 'award' : 'user'" :title="`${user.full_name}`">
    <div slot="tools">
      <div class="tabs is-toggle">
        <ul>
          <router-link exact tag="li" :to="{ name: 'user-overview'}">
            <a><span>{{ Naming.Overview }}</span></a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'user-job-history', params: { user: $route.params.user }}">
            <a><span>{{ Naming.Jobs }}</span></a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'user-documents', params: { user: $route.params.user }}">
            <a><span>Documents</span></a>
          </router-link>
          
          <router-link v-if="$root.hasAbility('manage-site-access', 'App\\User')" tag="li" :to="{ name: 'user-site-access', params: { user: $route.params.user }}">
            <a><span>{{ Naming.Site }} Access</span></a>
          </router-link>
          <router-link v-if="$root.hasAbility('manage-permissions', 'App\\User')" tag="li" :to="{ name: 'user-permissions', params: { user: $route.params.user }}">
            <a><span>Permissions</span></a>
          </router-link>
          <router-link exact tag="li" :to="{ name: 'user-edit'}" v-if="$root.hasAbility('edit-users', 'App\\User')">
            <a><span>Edit</span></a>
          </router-link>
        </ul>
      </div>
    </div>
    <router-view/>
  </page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    loading: true
  }),

  mounted() {

    Promise.all([
      this.$store.dispatch('company/load'),
      this.$store.dispatch('user/loadUser', this.$route.params.user)
    ]).then(() => {
      this.loading = false
    })
  },

  computed: {
    ...mapGetters('user', [
      'user',
      'isTechnician'
    ]),
    ...mapGetters('company', ['groupedNotificationChannels', 'company']),
    isPrimaryUser(user) {
      return this.user.id === this.company.primary_user_id
    }
  }
}
</script>
