<template>
<loader v-bind="{ loading }">
  <div class="is-flex justify-between align-center mb-1">
    <h3 class="is-size-4 has-text-primary">{{ Naming.Jobs }}</h3>

    <div class="is-flex">
      <text-input
        :label="false"
        :debounce="500"
        :value="filters.search"
        @input="filterBy('search', $event)"
        :placeholder="`Search by ${Naming.Job.toLowerCase()} number`"
        left-icon="search"
        class="is-marginless"
        classes="is-rounded">
        <action-button
          :disabled="!filters.search"
          slot="right"
          class="is-rounded"
          @click="clearFilter('search')">
          <icon icon="times"/>
        </action-button>
      </text-input>

      <action-button
        class="ml-2 is-rounded is-primary"
        @click="downloadJobHistoryReport">
        Download Report
      </action-button>
    </div>
  </div>

  <div class="box">
    <pager class="pl" :pageable="jobs" @nav="goToPage" jump-controls :context="Naming.Job.toLowerCase()" />
    <table class="table is-fullwidth is-vcentered">
      <thead>
        <tr>
          <th>{{ Naming.JobType }} &amp; Number</th>
          <th>{{ Naming.Site }} &amp; {{ Naming.Zones }}</th>
          <th>Start Date – Completed At</th>
          <th class="has-text-centered">{{ Naming.Inspections }}</th>
          <th class="has-text-right">Status</th>
        </tr>
      </thead>
      <tbody>
        <router-link tag="tr" class="cursor-pointer" :to="{ name: 'job-handler', params: { job: job.id, site: job.site_id }}" v-for="job in jobs.data" :key="job.id">
          <td class="id cursor-pointer">
            <span class="description">{{ job.type.description }}</span>
            <span class="has-text-weight-medium is-flex align-center justify-between">
              <div class="is-flex item-number">
                <span class="mr">{{ `#${job.number}` }}</span>
                <span v-if="job.user_id !== job.technician_id">opened by <route name="">{{ job.user.full_name }}</route></span>
                <span class="has-text-grey" v-else>opened by {{ Naming.Technician.toLowerCase() }}</span>
              </div>
              <icon data-tooltip="Scheduled" class="is-small has-tooltip-left has-tooltip-info has-text-grey" icon="sync sm" v-if="job.recurring_jobs_count"/>
            </span>
          </td>
          <td class="cursor-pointer">
            <span class="flex-link">
              <avatar class="mr-1" :size="30" :path="job.site.logo_url" :name="job.site.name"/>
              <div>
                <p>{{ job.site.name }}</p>
                <p class="is-size-7 has-text-grey">{{ job.zones_count || 'No' }} {{ 'assigned zone' | plural(job.zones_count) }}</p>
              </div>
            </span>
          </td>
          <td class="date cursor-pointer">
            <div class="is-flex flex-column">
              <span>{{ job.start_date | date('L') }}</span>
              <span class="is-size-7" v-if="job.completed_at">
                completed {{ job.completed_at | date('L LTS') }}
              </span>
              <span class="is-size-7" v-else>
                not yet completed
              </span>
            </div>
          </td>
          <td class="has-text-centered is-size-5 has-text-weight-light">
            {{ job.inspections.length }}
          </td>
          <td class="has-text-right cursor-pointer">
            <current-status :status="job.current_status"/>
          </td>
        </router-link>
      </tbody>
    </table>
    <p class="has-text-centered" v-if="!jobs.data.length">
      No {{ Naming.Jobs.toLowerCase() }} found
    </p>
    <pager class="pl" :pageable="jobs" @nav="goToPage" jump-controls :context="Naming.Job.toLowerCase()" />
  </div>
</loader>
</template>

<script>
import { mapGetters } from 'vuex'
import { user as backend } from '@/api'
import { downloadJobHistoryReport } from '@/modals'

export default {

  data: () => ({
    working: false,
    loading: true,
    filters: {
      search: ''
    }
  }),

  created() {
    this.loadJobs()
  },

  methods: {
    downloadJobHistoryReport,

    loadJobs() {
      this.$scrollToTop()
      return this.$store.dispatch('user/loadJobs', {
        path: '/api' + this.$route.fullPath
      }).then(() => {
        this.loading = false
      })
    },

    clearFilter(filter) {
      this.filters[filter] = null
      this.runFilters()
    },
    filterBy(key, value = '') {
      this.filters[key] = value
      this.runFilters()
    },
    runFilters() {
      this.$router.push({
        name: this.$route.name,
        query: this.filters
      })
    },
    goToPage(path) {
      this.$router.push(path)
    }
  },

  computed: {
    ...mapGetters('user', [
      'user',
      'jobs'
    ]),
  },

  watch: {
    $route: 'loadJobs',
  }

}
</script>
