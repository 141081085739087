<template>
  <page v-bind="{ loading }" :icon="'file-upload'" :title="`${Naming.Users} Import Tool`">
    <div class="columns">
      <div class="column">
        <!-- Download Import Template -->
        <div class="box">
          <h1 class="title is-3 is-marginless has-text-weight-light"><b>Step 1</b>: Download {{ Naming.User }} Import Template</h1>
          <action-button
            :working="isDownloadingTemplate"
            @click="downloadUserImportTemplate"
            left-icon="download"
            class="mt-2 is-primary"
          >
            Download (.xlsx)
          </action-button>
        </div>
      </div>
      <div class="column">
        <!-- Step 2: Upload & Import Template -->
        <div class="box">
          <h1 class="title is-3 is-marginless has-text-weight-light"><b>Step 2</b>: Import Populated Template</h1>
          <div class="mt-2">
            <file-input
              description="Only xlsx files accepted. Max file size: 50Mb"
              :error="$root.errors.file"
              @select="setImportFile"
              required>
              XLSX Document
            </file-input>
          </div>
          <action-button
            :disabled="!importFile"
            :working="isUploadingTemplate"
            @click="importTemplate"
            left-icon="upload"
            class="mt-2 is-primary"
          >
            Import
          </action-button>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { mapGetters } from 'vuex'
import download from 'downloadjs'
import { common as backend } from '@/api'

export default {

  data: () => ({
    loading: true,

    isDownloadingTemplate: false,
    isUploadingTemplate: false,
    importFile: null,
  }),

  created() {
    this.loading = false
  },

  methods: {
    downloadUserImportTemplate() {
      this.isDownloadingTemplate = true
      backend.downloadFile('/api/users/import/download', ({ data }) => {
        this.isDownloadingTemplate = false
        download(
          data,
          `Assetpool - Import ${this.Convert('Users')} Template.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        )
      }, error => {
        this.$whoops()
        this.isDownloadingTemplate = false
      })
    },

    setImportFile(file) {
      this.importFile = file
    },

    importTemplate() {
      this.isUploadingTemplate = true
      this.$store.dispatch('imports/importUsers', this.importFile).then(response => {
        this.$toast.success(this.Convert(`You'll receive a mail as soon as all your users have successfully imported.`))
        this.isUploadingTemplate = false
      }).catch(() => {
        this.isUploadingTemplate = false
      })
    },
  },
}
</script>
